import React from 'react'
import QuizResult from './QuizResult'
import StudentFooter from '../StudenLanding/StudentFooter'
import QuizTopBar from './QuizTopBar'

const StudentQuizResultIndex = () => {

    


  return (
    <div>
       <QuizTopBar/> 
      <QuizResult/>
      <StudentFooter/>
    </div>
  )
}

export default StudentQuizResultIndex
